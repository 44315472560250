import { Component } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Backseat Trader';

  constructor(private wowService: NgwWowService, private router: ActivatedRoute) {
    this.wowService.init();
    console.log(router.url)
  }
  
  async ngOnInit() {

  }
}

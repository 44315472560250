import { Component, OnInit, inject, TemplateRef } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavbarService } from '../navbarservice.service';

@Component({
  selector: 'app-profile-landing',
  templateUrl: './profile-landing.component.html',
  styleUrls: ['./profile-landing.component.css']
})
export class ProfileLandingComponent implements OnInit {

  constructor(public nav: NavbarService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.nav.hideSet(true);
  }

  seconds = 10;
  interrupt: boolean = false;

  intervalID;


  startTimer() {
    this.intervalID = setInterval(() => {
      this.seconds--;
      if(this.interrupt == false && this.seconds == 0){
        window.open("https://www.interactivebrokers.co.uk/portal/?action=ACCT_MGMT_MAIN&loginType=1&clt=0&locale=en_US&RL=1#/", "_blank");
        window.location.reload();
      }
    }, 1000)
  }

	closeResult = '';

	open(content: TemplateRef<any>) {
    this.interrupt = false;
    this.startTimer();
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.interrupt = true;
        this.seconds = 10;
        clearInterval(this.intervalID)
			},
		);
	}

	private getDismissReason(reason: any): string {
		switch (reason) {
			case ModalDismissReasons.ESC:
				return 'by pressing ESC';
			case ModalDismissReasons.BACKDROP_CLICK:
				return 'by clicking on a backdrop';
			default:
				return `with: ${reason}`;
		}
  }
}

import { Component, OnInit } from '@angular/core';
import { AnonymousSubject } from 'rxjs-compat';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SigninserviceService } from '../signinservice.service';
import { NavbarService } from '../navbarservice.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  focus=true;
  focus1=true;

  invalid;

 
  hide;
  submitted;
  showError;
  loading;

  loginform: FormGroup;

  email : string;
  password : string;

  constructor(private signInServ : SigninserviceService, private route : Router, private formBuilder : FormBuilder,  public nav: NavbarService) { }

  ngOnInit(): void {
    this.loginform = this.formBuilder.group({
      email: ['', [Validators.required, Validators.minLength(6), Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });

    // window.history.replaceState(null, null, "/login");
    this.nav.hideSet(true);
  }

  get f() { return this.loginform.controls; }



  async signIn(){
    this.email = this.f.email.value;
    this.password = this.f.password.value;
    //console.log(this.email);
    //console.log(this.password);
    this.signInServ.setParams(this.email, this.password).subscribe(
      response => {
        console.log(response);
        this.route.navigate(['/user']);
        this.invalid = "";
        const jsonData = JSON.stringify(response.user.username)
        const username = eval(jsonData);
        localStorage.setItem('username', username);
      }
    );
    this.invalid = "Invalid login details"
  }
  

}

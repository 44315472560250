import { Injectable } from '@angular/core';
// import { ForexConnect, OffersTable, Prices } from 'forex-connect';

declare var ForexConnect: any;
declare var fxcore: any;

@Injectable({
  providedIn: 'root'
})
export class FxcmService {
  private forexConnect: any;
  private session: any;
  private tableManager: any;

  constructor() {
    this.forexConnect = new ForexConnect();
    this.session = this.forexConnect.createSession();
    this.tableManager = this.session.getTableManager();
  }

  // private onConnected(session: any) {
  //   console.log('Connected to FXCM servers');
  //   this.session = session;
  //   this.offersTable = this.session.getTable(OffersTable);
  //   this.offersTable.subscribe(this.onOffersTableUpdate.bind(this));
  // }

  // private onDisconnected(reason: string) {
  //   console.log('Disconnected from FXCM servers:', reason);
  // }

  // private onOffersTableUpdate() {
  //   console.log('Offers table updated');
  //   const offers = this.offersTable.toArray();
  //   // Do something with offers
  // }

  // // public placeOrder(accountId: string, instrument: string, amount: number, isBuy: boolean) {
  // //   const request = this.session.createOrderRequest();
  // //   request.setAccountID(accountId);
  // //   request.setSymbol(instrument);
  // //   request.setOrderType(ForexConnect.Constants.OrderType.Spot);
  // //   request.setBuySell(isBuy ? ForexConnect.Constants.BuySell.Buy : ForexConnect.Constants.BuySell.Sell);
  // //   request.setAmount(amount);
  // //   request.setTimeInForce(ForexConnect.Constants.OrderTimeInForce.GoodTillCancel);
  // //   request.setOrderStatus(ForexConnect.Constants.OrderStatus.InRange);

  // //   const result = this.session.sendRequest(request);
  // //   if (result != ForexConnect.Constants.FXCMErrors.Success) {
  // //     console.error('Failed to place order:', result);
  // //   }
  // // }

  // public getPrices(instruments: string[]) {
  //   const prices = this.session.getTable(Prices);
  //   prices.setSubscription(instruments);
  //   prices.subscribe(this.onPricesUpdate.bind(this));
  // }

  // pricesTable;
  // private onPricesUpdate() {
  //   console.log('Prices updated');
  //   const prices = this.pricesTable.toArray();
  //   // Do something with prices
  // }
}
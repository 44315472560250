import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/providers/config/config.service';
import { map, first } from "rxjs/operators";
import { HttpClient, HttpParams } from '@angular/common/http';
import { ifStmt, THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { EmailValidator } from '@angular/forms';
import * as Highcharts from 'highcharts';
import { listenerCount } from 'process';
import { DatePipe } from '@angular/common';
import { bindCallback } from 'rxjs';
import { PlotsService } from '../plots.service';
@Component({
  selector: 'app-main-graph',
  templateUrl: './main-graph.component.html',
  styleUrls: ['./main-graph.component.css']
})
export class MainGraphComponent implements OnInit {

  tGain;
  tLaunch;
  nsdqVal;
  stampForGraph: any;
  username;

  monthlyGain = [];
  parsedMonthly = [];
  timestampMonthly = [];
  gainsMonthly = [];
  mobile: Boolean = false;
  newsletter: Boolean = false;

  constructor(private modalService: NgbModal, private http: HttpClient, private config: ConfigService, private router: Router, private date: DatePipe, private plots: PlotsService) { }

  ngOnInit(): void {
    this.username = "cosmicetoro";
    this.submit();

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }

    if(window.location.href.indexOf("newsletter") > -1 && this.mobile){
      this.newsletter = true;
    }else{
      this.newsletter = false;
    }
  }

  public options: any = {

    chart: {
      type: 'column'
    },
    title: {
      text: 'Our Monthly Gains to Date'
    },

    xAxis: {
      categories: ['Green', 'Pink']
    },

    series: [{
      data: [{
        name: 'Point 1',
        color: '#00FF00',
        y: 1
      }, {
        name: 'Point 2',
        color: '#FF00FF',
        y: 5
      }]
    }]
  }

  async getMonthlyGains() {
    const headers = { 'Ocp-Apim-Subscription-Key': '4e85f6e1911b41a385861c90b2444504' }
    await this.http.get<any>('https://api.etoro.com/API/User/V1/' + this.username + '/Gain?', { headers }).subscribe(data => {
      //console.log('data', data);
      this.monthlyGain = data;
      this.parsedMonthly = this.monthlyGain["monthly"];
      //console.log(this.parsedMonthly);

      this.parsedMonthly.forEach(i => {
        this.gainsMonthly.push(i['gain']);
        this.timestampMonthly.push(i['timestamp']);
      });
      this.stampForGraph = this.timestampMonthly[this.timestampMonthly.length - 1];
      console.log(this.timestampMonthly);
      console.log(this.stampForGraph);
      this.populateGraphWithDynamicValues();
      this.monthlyGain = [];
      this.parsedMonthly = [];
      this.gainsMonthly = [];
      this.timestampMonthly = [];
    })
  }

  convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat)
    return [pad(d.getMonth()+1), d.getFullYear()].join('/')
  }
  nPos = false;
  async getNSDQ() {
    const headers = {
      'cache-control': 'no-cache',
      'x-apikey': '63176c540b7c265b2a0e7818'
    }
    await this.http.get<any>('https://cosmicmedia-a0ce.restdb.io/rest/nsdqval', { headers }).subscribe(data => {
      data.forEach(number => {
        // console.log(number);
        this.nsdqVal = number.nsdq_number;
        if (this.nsdqVal < 0) {
          this.nPos = false;
          document.getElementById("tNSDQ").className = "legendRed";
        } else {
          this.nPos = true;
          document.getElementById("tNSDQ").className = "legendGreen";
        }
      })
    })


  }
  gPos = false;
  lPos = false;
  populateGraphWithDynamicValues() {
    var dateArr = [];
    var date;


    for (var i = 0; i <= this.timestampMonthly.length; i++) {
      date = new Date(this.timestampMonthly[i]);
      dateArr.push(this.convertDate(date))
    }

    let firstDate = dateArr.shift();

    let first = this.gainsMonthly.shift();
    let initialise = this.gainsMonthly[0];

    //console.log(this.gainsMonthly);
    let plot = [];
    initialise = (initialise * 12 / 1);
    // console.log(initialise);
    plot.push(initialise);
    let test = [2.88, 28.68, 37.80, 39.15, 17.23, 25.76, 24.94, 24.62, 10.01, 8.74, 10.65, 2.59, 2.86];
    let buff = [];

    for (var i = 0; i <= this.gainsMonthly.length - 1; i++) {
      if (i == 1) {
        buff.push(this.gainsMonthly[i] + this.gainsMonthly[i - 1]);
      } else {
        buff.push(this.gainsMonthly[i] + buff[i - 1]);
      }

      buff[i] = Math.round((buff[i] + Number.EPSILON) * 100) / 100;

    }

    buff[0] = this.gainsMonthly[0];

    // console.log(buff);
    let y = 1;

    for (var i = 0; i <= buff.length - 1; i++) {
      buff[i] = (buff[i] * 12 / y);
      y++;
      buff[i] = Math.round((buff[i] + Number.EPSILON) * 100) / 100;
    }

    // console.log(buff);

    this.tGain = buff[7];
    console.log(this.tGain)
    let x = this.tGain.toPrecision(3);
    console.log(x)
    this.tGain = x;
    this.tLaunch = buff[buff.length - 1];
    let z = this.tLaunch.toPrecision(3);
    this.tLaunch = z;

    if (this.tGain < 0) {
      this.gPos = false;
      document.getElementById("tGain").className = "legendRed";
    } else {
      this.gPos = true;
      document.getElementById("tGain").className = "legendGreen";
    }

    if (this.tLaunch < 0) {
      this.lPos = false;
      document.getElementById("tLaunch").className = "legendRed";
    } else {
      this.lPos = true;
      document.getElementById("tLaunch").className = "legendGreen";
    }

    let sum = 0;

    for (var i = 0; i <= 8; i++) {
      sum += buff[i];
    }

    // console.log(sum, "sum");

    let avg = sum / buff.length - 1;

    // console.log(avg, "avg");

    let agr = sum * 12 / 8;
    // console.log(agr);

    // for(var index = 0; index <= this.gainsMonthly.length; index++){
    //   this.gainsMonthly[index] = this.gainsMonthly[index] * 100;
    // }



    let plotBandText;
    let plotBandPos;
    let plotBandY;
    let plotBandTextCol;
    let plotBandAlign;
    let plotBandLabelText;
    let splineWidth;
    let arrowLengthDyn;
    let arrowWidthDyn;
    let dateOrNot;
    let gridOrNot;

    if (this.mobile == true) {
      plotBandText = "55%";
      plotBandPos = 'bottom';
      // plotBandY = 210;
      // plotBandTextCol = '#44AAD5';
      // plotBandAlign = 'center';
      // plotBandLabelText = 'Targeted Gain Range of 15 - 25%*';
      // splineWidth = '3';
      arrowLengthDyn = 7.5;
      arrowWidthDyn = 4;
      // dateOrNot = false;
      // plotBandText = "100%";
      plotBandPos = 'middle';
      plotBandY = 0;
      plotBandTextCol = '#44AAD5';
      plotBandAlign = 'center';
      // plotBandLabelText = 'Targeted Gain Range <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;of 15 - 25%';
      plotBandLabelText = 'Targeted Gain Range of 15 - 25%*';
      splineWidth = '3';
      // arrowLengthDyn = 15;
      // arrowWidthDyn = 8;
      dateOrNot = true;
      gridOrNot = 0;
    } else {
      plotBandText = "100%";
      plotBandPos = 'middle';
      plotBandY = 0;
      plotBandTextCol = '#44AAD5';
      plotBandAlign = 'center';
      // plotBandLabelText = 'Targeted Gain Range <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;of 15 - 25%';
      plotBandLabelText = 'Targeted Gain Range of 15 - 25%*';
      splineWidth = '5';
      arrowLengthDyn = 15;
      arrowWidthDyn = 8;
      dateOrNot = true;
      gridOrNot = 1;
    }



    // Try to change the type of grpah, it will work the way it is, need to adjus the values :)
    this.options = {
      chart: {
        events: {
          load: function () {
            this.customArrows = [];
          },
          render: function () {
            const chart = this,
              series1Data = chart.series[1].data;
            let point0, calculatedPath;
            point0 = series1Data[series1Data.length - 1];
            var arrowLength = arrowLengthDyn,
              arrowWidth = arrowWidthDyn,
              lastPoint = point0,
              nextLastPoint = series1Data[series1Data.length - 2],
              angle = Math.atan((lastPoint.plotX - nextLastPoint.plotX) /
                (lastPoint.plotY - nextLastPoint.plotY)),
              path = [];

            if (angle < 0) {
              angle = Math.PI + angle;
            }

            // pointDistance = Math.sqrt(
            //   (point0.plotX - point1.plotX) * (point0.plotX - point1.plotX) +
            //   (point0.plotY - point1.plotY) * (point0.plotY - point1.plotY)
            // );

            // angle = 90 + Math.atan2((point0.plotY - point1.plotY), (point0.plotX - point1.plotX)) * 180 / Math.PI;

            path.push('M', lastPoint.plotX, lastPoint.plotY);
            path.push(
              'L',
              lastPoint.plotX + arrowWidth * Math.cos(angle),
              lastPoint.plotY - arrowWidth * Math.sin(angle)
            );
            path.push(
              lastPoint.plotX + arrowLength * Math.sin(angle),
              lastPoint.plotY + arrowLength * Math.cos(angle)
            );
            path.push(
              lastPoint.plotX - arrowWidth * Math.cos(angle),
              lastPoint.plotY + arrowWidth * Math.sin(angle),
              'Z'
            );

            // console.log(angle);

            calculatedPath = path;

            if (!point0.customArrow) {
              point0.customArrow = chart.renderer.path().add(chart.series[1].group);
            }
            // console.log(calculatedPath)
            if (chart.series[1].visible && chart.series[0].visible) {
              point0.customArrow.attr({
                d: calculatedPath,
                fill: '#1F85DE',
                // 'stroke-width': 2,

              });
            } else {
              point0.customArrow.attr({
                d: ['M', -10, -10, 'L', -10, -10]
              });
            }



          }

          // }
        },

        type: 'column',

      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500,
            maxHeight: 200,
          },
          chartOptions: {
            legend: {
              enabled: false
            }
          }
        }]
      },
      xAxis: {
        categories: dateArr,
        labels: {
          enabled: dateOrNot, //to hide the dates
        }
      },
      yAxis: [{ // Primary yAxis
        labels: {
          format: '{value}%',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        },
        title: {
          text: '',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        },
        opposite: true

      }, { // Secondary yAxis

        title: {
          text: 'Monthly Return - (Target > 1.25%)',
          style: {
            color: "black",
          }
        },
        labels: {
          format: '{value}%',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        },
        tickPositioner: function(min, max) {
          var ticks = [],
            tick = min,
            step = Math.round((max - min) / 7);
            
          while (tick < max - step / 2) {
            ticks.push(Math.round(tick));
            tick += step;
          }
          ticks.push(Math.round(max));
          ticks.push(Math.round(max+step)); //hidden - added for top padding
          
          return ticks;
        },
        gridLineWidth: gridOrNot
      }, {
        gridLineWidth: gridOrNot,
        max: 40,
        tickInterval: 15,
         // Tertiary yAxis
        //   plotLines: [{
        //     value: 15,
        //     color: 'blue',
        //     dashStyle: 'longdash',
        //     width: 2,
        //     label: {
        //         text: 'Target AGR'
        //     }
        // }, {
        //     value: 25,
        //     color: 'blue',
        //     dashStyle: 'longdash',
        //     width: 2,
        //     label: {
        //         text: ''
        //     }
        // }],
        plotBands: [{
          from: 15,
          to: 25,
          color: 'rgba(68, 170, 213, 0.2)',
          label: {
            text: plotBandLabelText,
            verticalAlign: plotBandPos,
            align: plotBandAlign,
            y: plotBandY,
            style: {
              fontSize: plotBandText,
              fontWeight: 'bold',
              color: plotBandTextCol,

            }
          }
        }],
        title: {
          text: 'Annualised Gain Rate - (Target 15% - 25%)',
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
        labels: {
          format: '{value}%',
          style: {
            color: Highcharts.getOptions().colors[0]
          }
        },
        opposite: true
      }],

      title: {
        text: '',
      },
      plotOptions: {
        column: {
          maxPointWidth: 100,
          zones: [{
            value: 0, // Values up to 10 (not including) ...
            color: 'red' // ... have the color blue.
          }, {
            color: '#6AAC0E' // Values from 10 (including) and up have the color red
          }]
        },
        spline: {
          // shared options for all line series


          states: {
            hover: {
              enabled: false
            },


            inactive: {
              lineWidth: splineWidth,
            }
          }



        }
      },
      series: [{
        name: 'Monthly Gains',
        data: this.gainsMonthly,
        yAxis: 1,
      }, {
        yAxis: 2,
        type: 'spline',
        dashStyle: 'longdash',
        lineWidth: splineWidth,
        name: 'Annualised Gain Rate',
        color: '#1F85DE',
        data: buff,
        marker: {
          lineWidth: 1,
          lineColor: Highcharts.getOptions().colors[3],
          fillColor: 'white',
          enabled: false,

          //   symbol: 'triangle', 
          // fillColor: 'rgba(126,86,100,.9)', /* match to the color of your column */
          // radius:25
        }
      }]

    }
    Highcharts.chart('container', this.options);
  }

  async submit() {
    await this.getMonthlyGains();
    await this.getNSDQ();
  }


}

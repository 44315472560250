import { Component, OnInit } from '@angular/core';
import { NavbarService } from '../navbarservice.service';

@Component({
  selector: 'app-live-landing',
  templateUrl: './live-landing.component.html',
  styleUrls: ['./live-landing.component.css']
})
export class LiveLandingComponent implements OnInit {

  constructor(public nav: NavbarService) { }

  ngOnInit(): void {
    this.nav.hideSet(true);
  }

}

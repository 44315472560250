import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as myGlobals from 'Globals';

import { MultimediaserviceService } from '../multimediaservice.service';

declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  title = 'our Etoro Portfolio!';
  username;
  monthlyGain = [];
  parsedMonthly = [];
  timestampMonthly = [];
  gainsMonthly = [];

  display;

  

  finalGains = [];
  finalMonthly = [];
  public options: any = {

    chart: {
      type: 'line'
    },
    title: {
      text: 'Our Monthly Gains to Date'
    },

    xAxis: {
      categories: ['Green', 'Pink']
    },

    series: [{
      data: [{
        name: 'Point 1',
        color: '#00FF00',
        y: 1
      }, {
        name: 'Point 2',
        color: '#FF00FF',
        y: 5
      }]
    }]


  }

  constructor(
    private http: HttpClient,
    private multimediaService : MultimediaserviceService
  ) { }

  ngOnInit() {
    //Highcharts.chart('container', this.options);
    // console.log(this.finalGains);
    let userRole = this.multimediaService.getRole();
    if(userRole != "admin"){
      this.display = false;
    }
  }

  async submit() {
    this.riskAvg = 0;
    this.instruments = [];
    this.instrumentIds = [];
    this.instrumentIdVal = [];

    this.riskId = [];
    this.namesRequest = [];
    this.nameUrl = "";

    this.nameSet = [];
    this.nameVals = [];

    this.riskNames = [];
    //console.log(this.username);
    await this.getMonthlyGains();
    await this.getCopiers();
    await this.getLiveRiskScore();
    await this.getLivePortfolio();
    await this.getDiscovery();
    await this.getPerf();
    await this.getRisk();

  }

  async getMonthlyGains() {
    const headers = { 'Ocp-Apim-Subscription-Key': '621b619d6f8c4ef08d8659e0b034f873' }
    await this.http.get<any>('https://api.etoro.com/API/User/V1/' + this.username + '/Gain?', { headers }).subscribe(data => {
      //console.log('data', data);
      this.monthlyGain = data;
      this.parsedMonthly = this.monthlyGain["monthly"];
      //console.log(this.parsedMonthly);

      this.parsedMonthly.forEach(i => {
        this.gainsMonthly.push(i['gain']);
        this.timestampMonthly.push(i['timestamp']);
      });
      this.populateGraphWithDynamicValues();
      this.monthlyGain = [];
      this.parsedMonthly = [];
      this.gainsMonthly = [];
      this.timestampMonthly = [];
    })
  }

  populateGraphWithDynamicValues() {
    // Try to change the type of grpah, it will work the way it is, need to adjus the values :)
    this.options = {
      xAxis: {
        categories: this.timestampMonthly
      },
      title: {
        text: 'Monthly Gains',
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: -40,
        y: 60,
        floating: true,
        borderWidth: 1,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
        shadow: true
      },

      series: [{
        name: 'Monthly Gains',
        data: this.gainsMonthly
      }]

    }
    Highcharts.chart('container', this.options);
  }

  instruments = [];
  instrumentIds = [];
  instrumentIdVal = [];

  riskId = [];
  namesRequest = [];
  nameUrl = "";

  nameSet = [];
  nameVals = [];

  riskNames = [];
  portNames = [];

  async getLiveRiskScore() {
    const headers = { 'Ocp-Apim-Subscription-Key': '621b619d6f8c4ef08d8659e0b034f873' }
    await this.http.get<any>('https://api.etoro.com/API/User/V1/' + this.username + '/RiskScore/Live/', { headers }).subscribe(data => {
      this.instruments = data;
      //console.log(this.instruments);
      this.instrumentIds = this.instruments["contributers"];
      //console.log("this.instrumentIds - user set",this.instrumentIds);
      // this.instrumentIds has all details for risk
      this.instrumentIds.forEach(i => {
        this.instrumentIdVal.push(i['instrumentId']);
      });

      for (var i = 0; i <= this.instrumentIdVal.length - 1; i++) {
        if (i == this.instrumentIdVal.length - 1) {
          this.namesRequest.push("InstrumentIds=" + this.instrumentIdVal[i]);
        } else {
          this.namesRequest.push("InstrumentIds=" + this.instrumentIdVal[i] + "&");
        }
      }

      this.nameUrl = this.namesRequest.toString();
      var reqUrl = this.nameUrl.replace(/,/g, '');


      //console.log(reqUrl);

      const headers = { 'Ocp-Apim-Subscription-Key': '621b619d6f8c4ef08d8659e0b034f873' }
      this.http.get<any>('https://api.etoro.com/Metadata/V1/Instruments?' + reqUrl, { headers }).subscribe(data => {
       // console.log("metadata set",data)
        this.nameSet = data;
        this.nameSet.forEach(i => {
          this.nameVals.push(i["name"]);
        })
        console.log("names from metadata",this.nameVals);
        this.nameSet.forEach(i => {
          this.riskId.push(i["instrumentId"]);
        })

        this.riskId.sort((a, b) => a - b);
        this.instrumentIdVal.sort((a, b) => a - b);
        this.instrumentIds.sort((a,b) => a-b)
        //console.log(this.instrumentIdVal);

        for(var i = 0; i<=this.riskId.length - 1;i++){
          //console.log("test")
          this.instrumentIds[i].instrumentId = this.nameSet[i].name;
          this.portfolioPositions[i].positionId = this.nameVals[i];
         } 

      });



      // console.log("this.riskId - metadata set",this.riskId);
      // console.log("instrumentIdVal - user set", this.instrumentIdVal);
      // console.log(this.riskNames);



    })
    
  }

  portfolioSet = [];
  portfolioPositions = [];
  async getLivePortfolio() {
    const headers = { 'Ocp-Apim-Subscription-Key': '621b619d6f8c4ef08d8659e0b034f873' }
    await this.http.get<any>('https://api.etoro.com/API/User/V1/' + this.username + '/Portfolio/Live', { headers }).subscribe(data => {

      this.portfolioSet = data;
      //console.log(this.portfolioSet)
      this.portfolioPositions = this.portfolioSet["positions"];

      this.portfolioPositions.sort((a,b) => a-b);
     // console.log(this.portfolioPositions);
      //var test = this.nameVals;
     // console.log(test);
      //var max = this.portfolioPositions.length;
     // console.log(max);
     // this.portfolioPositions[1].positionId = "hello";
     // console.log(this.portfolioPositions[1].positionId);
     // for(var i = 0; i<=max - 1; i++){
         // console.log("testing")
         // this.portfolioPositions[i].positionId = this.nameVals[i];
     // }
    })
  }

  discoveryuserName;
  discoveryweeksSinceRegistration;
  discoverygain;
  discoverydailyGain;
  discoverythisWeekGain;
  discoveryriskScore;
  discoverycopiers;
  discoverycopiersGain;
  discoverytrades;
  discoverywinRatio;
  discoverydailyDD;
  discoveryweeklyDD;
  discoverypeakToValley;
  discoveryprofitableWeeksPct;
  discoveryprofitableMonthsPct;
  discoveryavgPosSize;
  async getDiscovery() {
    const headers = { 'Ocp-Apim-Subscription-Key': '621b619d6f8c4ef08d8659e0b034f873' }
    await this.http.get<any>('https://api.etoro.com/API/User/V1/' + this.username + '/Discovery?Period=LastYear', { headers }).subscribe(data => {

      this.discoveryuserName = data.userName;
      this.discoveryweeksSinceRegistration = data.weeksSinceRegistration;
      this.discoverygain = data.gain;
      this.discoverydailyGain = data.dailyGain;
      this.discoverythisWeekGain = data.thisWeekGain;
      this.discoveryriskScore = data.riskScore;
      this.discoverycopiers = data.copiers;
      this.discoverycopiersGain = data.copiersGain;
      this.discoverytrades = data.trades;
      this.discoverywinRatio = data.winRatio;
      this.discoverydailyDD = data.dailyDD;
      this.discoveryweeklyDD = data.weeklyDD;
      this.discoverypeakToValley = data.peakToValley;
      this.discoveryprofitableWeeksPct = data.profitableWeeksPct;
      this.discoveryprofitableMonthsPct = data.profitableMonthsPct;
      this.discoveryavgPosSize = data.avgPosSize;
    })
  }

  public perfOptions: any = {

    chart: {
      type: 'column'
    },
    title: {
      text: 'Column chart with negative values'
    },
    xAxis: {
      categories: ['Apples', 'Oranges', 'Pears', 'Grapes', 'Bananas']
    },
    credits: {
      enabled: false
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      x: -40,
      y: 60,
      floating: true,
      borderWidth: 1,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
      shadow: true
    },
    series: [{
      name: 'John',
      data: [5, 3, 4, 7, 2]
    }, {
      name: 'Jane',
      data: [2, -2, -3, 2, 1]
    }, {
      name: 'Joe',
      data: [3, 4, 4, -2, 5]
    }]

  }

  perfGain = [];
  parsedPerf = [];
  perfMonthly = [];
  perfTime = [];

  async getPerf() {
    const headers = { 'Ocp-Apim-Subscription-Key': '621b619d6f8c4ef08d8659e0b034f873' }
    await this.http.get<any>('https://api.etoro.com/API/User/V1/' + this.username + '/Gain?', { headers }).subscribe(data => {
      //console.log('data', data);
      this.perfGain = data;
      this.parsedPerf = this.perfGain["monthly"];
      //console.log(this.parsedMonthly);

      this.parsedPerf.forEach(i => {
        this.perfMonthly.push(i['gain']);
        this.perfTime.push(i['timestamp']);
      });
      this.populatePerf();
      //console.log(this.perfMonthly)
      this.perfGain = [];
      this.parsedPerf = [];
      this.perfMonthly = [];
      this.perfTime = [];
    })
  }

  populatePerf() {
    // Try to change the type of grpah, it will work the way it is, need to adjus the values :)
    this.options = {
      chart: {
        type: 'column'
      },
      xAxis: {
        categories: this.perfTime
      },
      title: {
        text: 'Performance',
      },
      series: [{
        name: 'Monthly Gains',
        data: this.perfMonthly
      }]

    }
    Highcharts.chart('containerPerf', this.options);
  }

  public riskOptions: any = {

    chart: {
      type: 'column'
    },
    title: {
      text: 'Column chart with negative values'
    },
    xAxis: {
      categories: ['Apples', 'Oranges', 'Pears', 'Grapes', 'Bananas']
    },
    credits: {
      enabled: false
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      x: -40,
      y: 60,
      floating: true,
      borderWidth: 1,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
      shadow: true
    },
    series: [{
      name: 'John',
      data: [5, 3, 4, 7, 2]
    }, {
      name: 'Jane',
      data: [2, -2, -3, 2, 1]
    }, {
      name: 'Joe',
      data: [3, 4, 4, -2, 5]
    }]

  }

  riskSet = [];
  parsedRisk = [];
  riskValues = [];
  riskTimes = [];
  riskAvg = 0;

  async getRisk() {
    const headers = { 'Ocp-Apim-Subscription-Key': '621b619d6f8c4ef08d8659e0b034f873' }
    await this.http.get<any>('https://api.etoro.com/API/User/V1/' + this.username + '/RiskScore/History', { headers }).subscribe(data => {
      //console.log('data', data);
      this.riskSet = data;
     // console.log(this.riskSet)
      this.parsedRisk = this.riskSet["riskScore"];

      this.riskSet.forEach(i => {
        this.riskValues.push(i['riskScore']);
        this.riskAvg = this.riskAvg + i['riskScore'];
        this.riskTimes.push(i['timestamp']);
      });
     // console.log(this.riskValues);
     // console.log(this.riskTimes);

      this.riskAvg = this.riskAvg / this.riskValues.length;
      //console.log(this.riskAvg);

      this.populateRisk();
      //console.log(this.perfMonthly)
      this.riskSet = [];
      this.parsedRisk = [];
      this.riskValues = [];
      this.riskTimes = [];
    })
  }

  populateRisk() {
    // Try to change the type of grpah, it will work the way it is, need to adjus the values :)
    this.options = {
      chart: {
        type: 'column'
      },
      xAxis: {
        categories: this.riskTimes
      },
      title: {
        text: 'Risk Score',
      },
      series: [{
        name: 'Risk Score',
        data: this.riskValues
      }]

    }
    Highcharts.chart('containerRisk', this.options);
  }

  public copiersOptions: any = {

    chart: {
      type: 'line'
    },
    title: {
      text: 'Our Monthly Gains to Date'
    },

    xAxis: {
      categories: ['Green', 'Pink']
    },

    series: [{
      data: [{
        name: 'Point 1',
        color: '#00FF00',
        y: 1
      }, {
        name: 'Point 2',
        color: '#FF00FF',
        y: 5
      }]
    }]


  }

  copiersSet = [];
  copiersParsed = [];
  recentCopiers = 0;
  copiersTime = [];

  async getCopiers() {
    const headers = { 'Ocp-Apim-Subscription-Key': '621b619d6f8c4ef08d8659e0b034f873' }
    await this.http.get<any>('https://api.etoro.com/API/User/V1/' + this.username + '/Copiers/History?MinDate=2021-11-24', { headers }).subscribe(data => {

      //console.log(data);
      this.copiersSet = data;
      this.copiersSet.forEach(i => {
        this.copiersParsed.push(i['copiers']);
        this.copiersTime.push(i['timestamp']);
      })
      //console.log(this.copiersParsed);
      var x = this.copiersParsed.length - 1;
      this.recentCopiers = this.copiersParsed[x];
      this.populateCopiers();
      //console.log(this.recentCopiers);
    })
  }

  populateCopiers() {
    // Try to change the type of grpah, it will work the way it is, need to adjus the values :)
    this.options = {
      xAxis: {
        categories: 0
      },
      title: {
        text: 'Copiers History',
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: -40,
        y: 60,
        floating: true,
        borderWidth: 1,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
        shadow: true
      },

      series: [{
        name: 'Copiers',
        data: this.copiersParsed
      }]

    }
    Highcharts.chart('copiersContainer', this.options);
  }



}

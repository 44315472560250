import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as myGlobals from 'Globals';
import { NavbarService } from '../navbarservice.service';

declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);


@Component({
  selector: 'app-userstats',
  templateUrl: './user-stats.component.html',
  styleUrls: ['./user-stats.component.css']
})
export class UserStatsComponent implements OnInit {
  title = 'our Etoro Portfolio!';
  tGain;
  tLaunch;
  nsdqVal;

  stampForGraph: any;

  username;
  monthlyGain = [];
  parsedMonthly = [];
  timestampMonthly = [];
  gainsMonthly = [];

  finalGains = [];
  finalMonthly = [];

  mobile: Boolean = false;
  public options: any = {

    chart: {
      type: 'line'
    },
    title: {
      text: 'Our Monthly Gains to Date'
    },

    xAxis: {
      categories: ['Green', 'Pink']
    },

    series: [{
      data: [{
        name: 'Point 1',
        color: '#00FF00',
        y: 1
      }, {
        name: 'Point 2',
        color: '#FF00FF',
        y: 5
      }]
    }]


  }

  constructor(
    private http: HttpClient, public nav: NavbarService
  ) { }

  ngOnInit() {
    //Highcharts.chart('container', this.options);
    // console.log(this.finalGains);
    this.username = (localStorage.getItem('username'));
    this.submit();
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.mobile = true;
    } else {
      this.mobile = false;;
    }

    // window.history.replaceState(null, null, "/user");
    this.nav.hideSet(true);
   
  }

  nPos = false;
  async getNSDQ() {
    const headers = {
      'cache-control': 'no-cache',
      'x-apikey': '63176c540b7c265b2a0e7818'
    }
    await this.http.get<any>('https://cosmicmedia-a0ce.restdb.io/rest/nsdqval', { headers }).subscribe(data => {
      data.forEach(number => {
        // console.log(number);
        this.nsdqVal = number.nsdq_number;
        if (this.nsdqVal < 0) {
          this.nPos = false;
          document.getElementById("tNSDQ").className = "legendRed";
        } else {
          this.nPos = true;
          document.getElementById("tNSDQ").className = "legendGreen";
        }
      })
    })


  }

  convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat)
    return [pad(d.getMonth()+1), d.getFullYear()].join('/')
  }


  async submit() {
    this.riskAvg = 0;
    this.instruments = [];
    this.instrumentIds = [];
    this.instrumentIdVal = [];

    this.riskId = [];
    this.namesRequest = [];
    this.nameUrl = "";

    this.nameSet = [];
    this.nameVals = [];

    this.riskNames = [];
    //console.log(this.username);
    await this.getMonthlyGains();
    await this.getCopiers();
    await this.getLiveRiskScore();
    await this.getLivePortfolio();
    await this.getDiscovery();
    await this.getPerf();
    await this.getRisk();
    await this.getNSDQ();

  }

  async getMonthlyGains() {
    const headers = { 'Ocp-Apim-Subscription-Key': '4e85f6e1911b41a385861c90b2444504' }
    await this.http.get<any>('https://api.etoro.com/API/User/V1/' + this.username + '/Gain?', { headers }).subscribe(data => {
      //console.log('data', data);
      this.monthlyGain = data;
      this.parsedMonthly = this.monthlyGain["monthly"];
      //console.log(this.parsedMonthly);

      this.parsedMonthly.forEach(i => {
        this.gainsMonthly.push(i['gain']);
        this.timestampMonthly.push(i['timestamp']);
      });
      this.populateGraphWithDynamicValues();
      this.monthlyGain = [];
      this.parsedMonthly = [];
      this.gainsMonthly = [];
      this.timestampMonthly = [];
    })
  }

  gPos = false;
  lPos = false;
  populateGraphWithDynamicValues() {
    var dateArr = [];
    var date;


    for (var i = 0; i <= this.timestampMonthly.length; i++) {
      date = new Date(this.timestampMonthly[i]);
      dateArr.push(this.convertDate(date))
    }

    let firstDate = dateArr.shift();

    let first = this.gainsMonthly.shift();
    let initialise = this.gainsMonthly[0];

    //console.log(this.gainsMonthly);
    let plot = [];
    initialise = (initialise * 12 / 1);
    // console.log(initialise);
    plot.push(initialise);
    let test = [2.88, 28.68, 37.80, 39.15, 17.23, 25.76, 24.94, 24.62, 10.01, 8.74, 10.65, 2.59, 2.86];
    let buff = [];

    for (var i = 0; i <= this.gainsMonthly.length - 1; i++) {
      if (i == 1) {
        buff.push(this.gainsMonthly[i] + this.gainsMonthly[i - 1]);
      } else {
        buff.push(this.gainsMonthly[i] + buff[i - 1]);
      }

      buff[i] = Math.round((buff[i] + Number.EPSILON) * 100) / 100;

    }

    buff[0] = this.gainsMonthly[0];

    // console.log(buff);
    let y = 1;

    for (var i = 0; i <= buff.length - 1; i++) {
      buff[i] = (buff[i] * 12 / y);
      y++;
      buff[i] = Math.round((buff[i] + Number.EPSILON) * 100) / 100;
    }

    // console.log(buff);

    this.tGain = buff[7];
    console.log(this.tGain)
    let x = this.tGain.toPrecision(3);
    console.log(x)
    this.tGain = x;
    this.tLaunch = buff[buff.length - 1];
    let z = this.tLaunch.toPrecision(3);
    this.tLaunch = z;

    if (this.tGain < 0) {
      this.gPos = false;
      document.getElementById("tGain").className = "legendRed";
    } else {
      this.gPos = true;
      document.getElementById("tGain").className = "legendGreen";
    }

    if (this.tLaunch < 0) {
      this.lPos = false;
      document.getElementById("tLaunch").className = "legendRed";
    } else {
      this.lPos = true;
      document.getElementById("tLaunch").className = "legendGreen";
    }

    let sum = 0;

    for (var i = 0; i <= 8; i++) {
      sum += buff[i];
    }

    // console.log(sum, "sum");

    let avg = sum / buff.length - 1;

    // console.log(avg, "avg");

    let agr = sum * 12 / 8;
    // console.log(agr);

    // for(var index = 0; index <= this.gainsMonthly.length; index++){
    //   this.gainsMonthly[index] = this.gainsMonthly[index] * 100;
    // }



    let plotBandText;
    let plotBandPos;
    let plotBandY;
    let plotBandTextCol;
    let plotBandAlign;
    let plotBandLabelText;
    let splineWidth;
    let arrowLengthDyn;
    let arrowWidthDyn;
    let dateOrNot;
    let gridOrNot;

    if (this.mobile == true) {
      plotBandText = "74%";
      plotBandPos = 'middle';
      plotBandY = 0;
      plotBandTextCol = '#44AAD5';
      plotBandAlign = 'center';
      plotBandLabelText = 'Targeted Gain Range of 15 - 25%*';
      splineWidth = '3';
      arrowLengthDyn = 7.5;
      arrowWidthDyn = 4;
      dateOrNot = false;
      gridOrNot = 0;
    } else {
      plotBandText = "100%";
      plotBandPos = 'middle';
      plotBandY = 0;
      plotBandTextCol = '#44AAD5';
      plotBandAlign = 'center';
      // plotBandLabelText = 'Targeted Gain Range <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;of 15 - 25%';
      plotBandLabelText = 'Targeted Gain Range of 15 - 25%*';
      splineWidth = '5';
      arrowLengthDyn = 15;
      arrowWidthDyn = 8;
      dateOrNot = true;
      gridOrNot = 0;
    }



    // Try to change the type of grpah, it will work the way it is, need to adjus the values :)
    this.options = {
      chart: {
        events: {
          load: function () {
            this.customArrows = [];
          },
          render: function () {
            const chart = this,
              series1Data = chart.series[1].data;
            let point0, calculatedPath;
            point0 = series1Data[series1Data.length - 1];
            var arrowLength = arrowLengthDyn,
              arrowWidth = arrowWidthDyn,
              lastPoint = point0,
              nextLastPoint = series1Data[series1Data.length - 2],
              angle = Math.atan((lastPoint.plotX - nextLastPoint.plotX) /
                (lastPoint.plotY - nextLastPoint.plotY)),
              path = [];

            if (angle < 0) {
              angle = Math.PI + angle;
            }

            // pointDistance = Math.sqrt(
            //   (point0.plotX - point1.plotX) * (point0.plotX - point1.plotX) +
            //   (point0.plotY - point1.plotY) * (point0.plotY - point1.plotY)
            // );

            // angle = 90 + Math.atan2((point0.plotY - point1.plotY), (point0.plotX - point1.plotX)) * 180 / Math.PI;

            path.push('M', lastPoint.plotX, lastPoint.plotY);
            path.push(
              'L',
              lastPoint.plotX + arrowWidth * Math.cos(angle),
              lastPoint.plotY - arrowWidth * Math.sin(angle)
            );
            path.push(
              lastPoint.plotX + arrowLength * Math.sin(angle),
              lastPoint.plotY + arrowLength * Math.cos(angle)
            );
            path.push(
              lastPoint.plotX - arrowWidth * Math.cos(angle),
              lastPoint.plotY + arrowWidth * Math.sin(angle),
              'Z'
            );

            // console.log(angle);

            calculatedPath = path;

            if (!point0.customArrow) {
              point0.customArrow = chart.renderer.path().add(chart.series[1].group);
            }
            // console.log(calculatedPath)
            if (chart.series[1].visible && chart.series[0].visible) {
              point0.customArrow.attr({
                d: calculatedPath,
                fill: '#1F85DE',
                // 'stroke-width': 2,

              });
            } else {
              point0.customArrow.attr({
                d: ['M', -10, -10, 'L', -10, -10]
              });
            }



          }

          // }
        },

        type: 'column',

      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500,
            maxHeight: 200,
          },
          chartOptions: {
            legend: {
              enabled: false
            }
          }
        }]
      },
      xAxis: {
        categories: dateArr,
        labels: {
          enabled: dateOrNot, //to hide the dates
        }
      },
      yAxis: [{ // Primary yAxis
        labels: {
          format: '{value}%',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        },
        title: {
          text: '',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        },
        opposite: true

      }, { // Secondary yAxis

        title: {
          text: 'Monthly Return - (Target > 1.25%)',
          style: {
            color: "black",
          }
        },
        labels: {
          format: '{value}%',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        },
        tickPositioner: function(min, max) {
          var ticks = [],
            tick = min,
            step = Math.round((max - min) / 7);
            
          while (tick < max - step / 2) {
            ticks.push(Math.round(tick));
            tick += step;
          }
          ticks.push(Math.round(max));
          ticks.push(Math.round(max+step)); //hidden - added for top padding
          
          return ticks;
        },
        gridLineWidth: gridOrNot

      }, { 
        gridLineWidth: gridOrNot,
        max: 40,
        tickInterval: 15,
        plotBands: [{
          from: 15,
          to: 25,
          color: 'rgba(68, 170, 213, 0.2)',
          label: {
            text: plotBandLabelText,
            verticalAlign: plotBandPos,
            align: plotBandAlign,
            y: plotBandY,
            style: {
              fontSize: plotBandText,
              fontWeight: 'bold',
              color: plotBandTextCol,

            }
          }
        }],
        title: {
          text: 'Annualised Gain Rate - (Target 15% - 25%)',
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
        labels: {
          format: '{value}%',
          style: {
            color: Highcharts.getOptions().colors[0]
          }
        },
        opposite: true
      }],

      title: {
        text: '',
      },
      plotOptions: {
        column: {
          maxPointWidth: 100,
          zones: [{
            value: 0, // Values up to 10 (not including) ...
            color: 'red' // ... have the color blue.
          }, {
            color: '#6AAC0E' // Values from 10 (including) and up have the color red
          }]
        },
        spline: {
          // shared options for all line series


          states: {
            hover: {
              enabled: false
            },


            inactive: {
              lineWidth: splineWidth,
            }
          }



        }
      },
      series: [{
        name: 'Monthly Gains',
        data: this.gainsMonthly,
        yAxis: 1,
      }, {
        yAxis: 2,
        type: 'spline',
        dashStyle: 'longdash',
        lineWidth: splineWidth,
        name: 'Annualised Gain Rate',
        color: '#1F85DE',
        data: buff,
        marker: {
          lineWidth: 1,
          lineColor: Highcharts.getOptions().colors[3],
          fillColor: 'white',
          enabled: false,

          //   symbol: 'triangle', 
          // fillColor: 'rgba(126,86,100,.9)', /* match to the color of your column */
          // radius:25
        }
      }]

    }
    Highcharts.chart('container', this.options);
  }

  instruments = [];
  instrumentIds = [];
  instrumentIdVal = [];

  riskId = [];
  namesRequest = [];
  nameUrl = "";

  nameSet = [];
  nameVals = [];

  riskNames = [];
  portNames = [];

  async getLiveRiskScore() {
    const headers = { 'Ocp-Apim-Subscription-Key': '4e85f6e1911b41a385861c90b2444504' }
    await this.http.get<any>('https://api.etoro.com/API/User/V1/' + this.username + '/RiskScore/Live/', { headers }).subscribe(data => {
      this.instruments = data;
      //console.log(this.instruments);
      this.instrumentIds = this.instruments["contributers"];
      //console.log("this.instrumentIds - user set",this.instrumentIds);
      // this.instrumentIds has all details for risk
      this.instrumentIds.forEach(i => {
        this.instrumentIdVal.push(i['instrumentId']);
      });

      for (var i = 0; i <= this.instrumentIdVal.length - 1; i++) {
        if (i == this.instrumentIdVal.length - 1) {
          this.namesRequest.push("InstrumentIds=" + this.instrumentIdVal[i]);
        } else {
          this.namesRequest.push("InstrumentIds=" + this.instrumentIdVal[i] + "&");
        }
      }

      this.nameUrl = this.namesRequest.toString();
      var reqUrl = this.nameUrl.replace(/,/g, '');


      //console.log(reqUrl);

      const headers = { 'Ocp-Apim-Subscription-Key': '4e85f6e1911b41a385861c90b2444504' }
      this.http.get<any>('https://api.etoro.com/Metadata/V1/Instruments?' + reqUrl, { headers }).subscribe(data => {
       // console.log("metadata set",data)
        this.nameSet = data;
        this.nameSet.forEach(i => {
          this.nameVals.push(i["name"]);
        })
        //console.log("names from metadata",this.nameVals);
        this.nameSet.forEach(i => {
          this.riskId.push(i["instrumentId"]);
        })

        this.riskId.sort((a, b) => a - b);
        this.instrumentIdVal.sort((a, b) => a - b);
        this.instrumentIds.sort((a,b) => a-b)
        //console.log(this.instrumentIdVal);
        let timestampArr = [];
        for(var i = 0; i<=this.riskId.length - 1;i++){
          //console.log("test")
          this.instrumentIds[i].instrumentId = this.nameSet[i].name;
          this.portfolioPositions[i].positionId = this.nameVals[i];
          let date = new Date(this.portfolioPositions[i].timestamp);
          timestampArr.push(date);
          this.portfolioPositions[i].timestamp = timestampArr[i];
         
         } 

      });



      // console.log("this.riskId - metadata set",this.riskId);
      // console.log("instrumentIdVal - user set", this.instrumentIdVal);
      // console.log(this.riskNames);



    })
    
  }

  portfolioSet = [];
  portfolioPositions = [];
  async getLivePortfolio() {
    const headers = { 'Ocp-Apim-Subscription-Key': '4e85f6e1911b41a385861c90b2444504' }
    await this.http.get<any>('https://api.etoro.com/API/User/V1/' + this.username + '/Portfolio/Live', { headers }).subscribe(data => {

      this.portfolioSet = data;
      //console.log(this.portfolioSet)
      this.portfolioPositions = this.portfolioSet["positions"];

      this.portfolioPositions.sort((a,b) => a-b);
      //console.log(this.portfolioPositions);
      //var test = this.nameVals;
     // console.log(test);
      //var max = this.portfolioPositions.length;
     // console.log(max);
     // this.portfolioPositions[1].positionId = "hello";
     // console.log(this.portfolioPositions[1].positionId);
     // for(var i = 0; i<=max - 1; i++){
         // console.log("testing")
         // this.portfolioPositions[i].positionId = this.nameVals[i];
     // }
    })
  }

  discoveryuserName;
  discoveryweeksSinceRegistration;
  discoverygain;
  discoverydailyGain;
  discoverythisWeekGain;
  discoveryriskScore;
  discoverycopiers;
  discoverycopiersGain;
  discoverytrades;
  discoverywinRatio;
  discoverydailyDD;
  discoveryweeklyDD;
  discoverypeakToValley;
  discoveryprofitableWeeksPct;
  discoveryprofitableMonthsPct;
  discoveryavgPosSize;
  async getDiscovery() {
    const headers = { 'Ocp-Apim-Subscription-Key': '4e85f6e1911b41a385861c90b2444504' }
    await this.http.get<any>('https://api.etoro.com/API/User/V1/' + this.username + '/Discovery?Period=LastYear', { headers }).subscribe(data => {

      this.discoveryuserName = data.userName;
      this.discoveryweeksSinceRegistration = data.weeksSinceRegistration;
      this.discoverygain = data.gain;
      this.discoverydailyGain = data.dailyGain;
      this.discoverythisWeekGain = data.thisWeekGain;
      this.discoveryriskScore = data.riskScore;
      this.discoverycopiers = data.copiers;
      this.discoverycopiersGain = data.copiersGain;
      this.discoverytrades = data.trades;
      this.discoverywinRatio = data.winRatio;
      this.discoverydailyDD = data.dailyDD;
      this.discoveryweeklyDD = data.weeklyDD;
      this.discoverypeakToValley = data.peakToValley;
      this.discoveryprofitableWeeksPct = data.profitableWeeksPct;
      this.discoveryprofitableMonthsPct = data.profitableMonthsPct;
      this.discoveryavgPosSize = data.avgPosSize;
    })
  }

  public perfOptions: any = {

    chart: {
      type: 'column'
    },
    title: {
      text: 'Column chart with negative values'
    },
    xAxis: {
      categories: ['Apples', 'Oranges', 'Pears', 'Grapes', 'Bananas']
    },
    credits: {
      enabled: false
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      x: -40,
      y: 60,
      floating: true,
      borderWidth: 1,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
      shadow: true
    },
    series: [{
      name: 'John',
      data: [5, 3, 4, 7, 2]
    }, {
      name: 'Jane',
      data: [2, -2, -3, 2, 1]
    }, {
      name: 'Joe',
      data: [3, 4, 4, -2, 5]
    }]

  }

  perfGain = [];
  parsedPerf = [];
  perfMonthly = [];
  perfTime = [];

 
  async getPerf() {
    const headers = { 'Ocp-Apim-Subscription-Key': '4e85f6e1911b41a385861c90b2444504' }
    await this.http.get<any>('https://api.etoro.com/API/User/V1/' + this.username + '/Gain?', { headers }).subscribe(data => {
      //console.log('data', data);
      this.perfGain = data;
      this.parsedPerf = this.perfGain["monthly"];
      //console.log(this.parsedMonthly);

      this.parsedPerf.forEach(i => {
        this.perfMonthly.push(i['gain']);
        this.perfTime.push(i['timestamp']);
      });
      this.populatePerf();
      console.log(this.perfMonthly)
      this.perfGain = [];
      this.parsedPerf = [];
      this.perfMonthly = [];
      this.perfTime = [];
    })
  }

  populatePerf() {
    var dateArr = [];
    var date;
    
    for(var i = 0; i<=this.perfTime.length; i++){
      date = new Date(this.perfTime[i]);
      dateArr.push(this.convertDate(date))
      console.log(dateArr[i]);
    }
    // Try to change the type of grpah, it will work the way it is, need to adjus the values :)
    this.options = {
      chart: {
        type: 'column'
      },
      xAxis: {
        categories: dateArr
      },
      title: {
        text: 'Performance',
      },
      series: [{
        name: 'Monthly Gains',
        data: this.perfMonthly
      }]

    }
    Highcharts.chart('containerPerf', this.options);
  }

  public riskOptions: any = {

    chart: {
      type: 'column'
    },
    title: {
      text: 'Column chart with negative values'
    },
    xAxis: {
      categories: ['Apples', 'Oranges', 'Pears', 'Grapes', 'Bananas']
    },
    credits: {
      enabled: false
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      x: -40,
      y: 60,
      floating: true,
      borderWidth: 1,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
      shadow: true
    },
    series: [{
      name: 'John',
      data: [5, 3, 4, 7, 2]
    }, {
      name: 'Jane',
      data: [2, -2, -3, 2, 1]
    }, {
      name: 'Joe',
      data: [3, 4, 4, -2, 5]
    }]

  }

  riskSet = [];
  parsedRisk = [];
  riskValues = [];
  riskTimes = [];
  riskAvg = 0;
  riskText;

  async getRisk() {
    const headers = { 'Ocp-Apim-Subscription-Key': '4e85f6e1911b41a385861c90b2444504' }
    await this.http.get<any>('https://api.etoro.com/API/User/V1/' + this.username + '/RiskScore/History', { headers }).subscribe(data => {
      //console.log('data', data);
      this.riskSet = data;
      // console.log(this.riskSet)
      this.parsedRisk = this.riskSet["riskScore"];

      this.riskSet.forEach(i => {
        this.riskValues.push(i['riskScore']);
        this.riskAvg = this.riskAvg + i['riskScore'];
        this.riskTimes.push(i['timestamp']);
      });
      // console.log(this.riskValues);
      // console.log(this.riskTimes);

      this.riskAvg = this.riskAvg / this.riskValues.length;
      let buff = this.riskAvg.toString(); //If it's not already a String
      buff = buff.slice(0, (buff.indexOf(".")) + 3);
      this.riskText = buff;
      //console.log(this.riskAvg);

      this.populateRisk();
      //console.log(this.perfMonthly)
      this.riskSet = [];
      this.parsedRisk = [];
      this.riskValues = [];
      this.riskTimes = [];
    })
  }

  populateRisk() {
    var dateArr = [];
    var date;

    for (var i = 0; i <= this.riskTimes.length; i++) {
      date = new Date(this.riskTimes[i]);
      dateArr.push(this.convertDate(date))
      console.log(dateArr[i]);
    }

    let max = 0;
    for (let i = 0; i <= this.riskValues.length - 1; i++) {
      if (max <= this.riskValues[i]) {
        max = this.riskValues[i];
      }
    }
    // Try to change the type of grpah, it will work the way it is, need to adjus the values :)
    this.options = {
      chart: {
        type: 'column'
      },
      yAxis: {
        min: 2
      },
      xAxis: {
        categories: dateArr
      },
      title: {
        text: '',
      },
      plotOptions: {
        column: {
          zones: [{
            value: max, // Values up to 10 (not including) ...
            color: '#A5FF70' // ... have the color blue.
          }, {
            color: '#FFD754' // Values from 10 (including) and up have the color red
          }]
        }
      },
      series: [{
        name: 'Risk Score',
        data: this.riskValues
      }]

    }
    Highcharts.chart('containerRisk', this.options);
  }

  copiersSet = [];
  copiersParsed = [];
  recentCopiers = 0;
  copiersTime = [];

  async getCopiers() {
    const headers = { 'Ocp-Apim-Subscription-Key': '4e85f6e1911b41a385861c90b2444504' }
    await this.http.get<any>('https://api.etoro.com/API/User/V1/' + this.username + '/Copiers/History?MinDate=2021-11-24', { headers }).subscribe(data => {

      //console.log(data);
      this.copiersSet = data;
      this.copiersSet.forEach(i => {
        this.copiersParsed.push(i['copiers']);
        this.copiersTime.push(i['timestamp']);
      })
      //console.log(this.copiersParsed);
      var x = this.copiersParsed.length - 1;
      this.recentCopiers = this.copiersParsed[x];
      this.populateCopiers();
      //console.log(this.recentCopiers);
    })
  }

  populateCopiers() {
this.options = {
      chart: {
        type: 'area'
      },
      yAxis: {
        min: 4
      },
      xAxis: {
        categories: 0
      },
      title: {
        text: 'Copiers History',
      },
      series: [{
        name: 'Copiers',
        data: this.copiersParsed
      }]

    }
    Highcharts.chart('copiersContainer', this.options);
  }


  logout(){
    localStorage.removeItem('username');
    location.reload();
  }

}

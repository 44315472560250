import { Component, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-intervideo-content',
  template: `
  <div class="modal-body" style="text-align: center;"> 
      <div class="row">
          <div class="col">
              <div class="video-container">
              <iframe src="https://drive.google.com/file/d/15QSpbaC6C3Az0RdBQePT5W3vkejbTu1r/preview" width="1000"
        height="300" allow="autoplay"></iframe>
              </div>
          </div>
      </div>
  </div>
  <br/>
  <div class="modal-footer">
      <div class="left-side" style="margin: auto">
          <button type="button" class="btn" (click)="activeModal.close('Close click')">Close</button>
      </div>
      <div class="divider"></div>
  </div>
  `,
  styleUrls: ['./inter-video.component.css']
})
export class InterVideoContent  {
  @Input() name;

  constructor(public activeModal: NgbActiveModal) { }


}

@Component({
  selector: 'app-intervideo-component',
  templateUrl: './inter-video.component.html',
  styleUrls: ['./inter-video.component.css']

})
export class InterVideoComponent  {
  constructor(private modalService: NgbModal) { }
  open() {
      const modalRef = this.modalService.open(InterVideoContent, { windowClass: 'largeModal' });
      modalRef.componentInstance.name = 'World';
  }

}

import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NavbarService } from '../navbarservice.service';

@Component({
  selector: 'app-seo',
  templateUrl: './seo.component.html',
  styleUrls: ['./seo.component.css']
})
export class SeoComponent implements OnInit, AfterViewInit {

  etoro = false;
  smartStock = false;
  advanced = false;
  diversified = false;

  bubbles = false;
  bubbles1 = false;
  bubbles2 = false;
  bubbles3 = false;
  bubbles4 = false;
  bubbles5 = false;
  bubbles6 = false;
  how = false;

  theState = [];

  mobile: Boolean = false;

  constructor(private route: Router, private meta: Meta, private _location: Location, private nav: NavbarService) { }

  ngOnInit(): void {
    // this.getInfo();
    this.theState = [];
    // this.getInfo();

    this.meta.addTags([
      { name: 'title', content: 'IBKR Platform: Comprehensive Algorithmic Trading for S&P 500 Stocks'},
      { name: 'description', content: 'Trade S&P 500 stocks algorithmically with the IBKR platform, offering powerful tools, APIs, low costs, and robust support for efficient and effective strategies' }
    ]);

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }

    this.nav.hideSet(true);
  }

  ngAfterViewInit(): void {

    let winPosSEO = sessionStorage.getItem("windowPosSubSEO");
    if(winPosSEO){
      // console.log("found!")
      let numWinPosSEO = Number(winPosSEO);
      // window.location.replace('http://localhost:4200/');
      if(numWinPosSEO > 0){
        // console.log("scrolling!", numWinPosBubbles)
        window.scrollTo(0, numWinPosSEO);
        // console.log(window.scrollY)
        if(window.scrollY != numWinPosSEO){
          // console.log("scrolling again!")
          window.scrollTo(0, numWinPosSEO)
        }
      }
    }
  }



  getInfo() {
    // console.log("here")
    let state = localStorage.getItem('info');
    this.theState.push(state);

    if (state == 'etoro') {
      this.etoro = true;
      this.smartStock = false;
      this.advanced = false;
      this.diversified = false;

      this.bubbles = false;
      this.bubbles1 = false;
      this.bubbles2 = false;
      this.bubbles3 = false;
      this.bubbles4 = false;
      this.bubbles5 = false;
      this.bubbles6 = false;
      this.how = false;
      this.route.navigate(['/information']);


    } else if (state == 'smartStock') {
      this.etoro = false;
      this.smartStock = true;
      this.advanced = false;
      this.diversified = false;

      this.bubbles = false;
      this.bubbles1 = false;
      this.bubbles2 = false;
      this.bubbles3 = false;
      this.bubbles4 = false;
      this.bubbles5 = false;
      this.bubbles6 = false;
      this.how = false;
      this.route.navigate(['/information/2']);

    } else if (state == 'advanced') {
      this.etoro = false;
      this.smartStock = false;
      this.advanced = true;
      this.diversified = false;

      this.bubbles = false;
      this.bubbles1 = false;
      this.bubbles2 = false;
      this.bubbles3 = false;
      this.bubbles4 = false;
      this.bubbles5 = false;
      this.bubbles6 = false;
      this.how = false;
      this.route.navigate(['/information/3']);

    } else if (state == 'diversified') {
      this.etoro = false;
      this.smartStock = false;
      this.advanced = false;
      this.diversified = true;

      this.bubbles = false;
      this.bubbles1 = false;
      this.bubbles2 = false;
      this.bubbles3 = false;
      this.bubbles4 = false;
      this.bubbles5 = false;
      this.bubbles6 = false;
      this.how = false;
      this.route.navigate(['/information/4']);

    } else if (state == 'bubbles') {
      this.etoro = false;
      this.smartStock = false;
      this.advanced = false;
      this.diversified = false;

      this.bubbles = true;
      this.bubbles1 = false;
      this.bubbles2 = false;
      this.bubbles3 = false;
      this.bubbles4 = false;
      this.bubbles5 = false;
      this.bubbles6 = false;
      this.how = false;
      this.route.navigate(['/information/5']);

    } else if (state == 'how') {
      this.etoro = false;
      this.smartStock = false;
      this.advanced = false;
      this.diversified = false;

      this.bubbles = false;
      this.bubbles1 = false;
      this.bubbles2 = false;
      this.bubbles3 = false;
      this.bubbles4 = false;
      this.bubbles5 = false;
      this.bubbles6 = false;
      this.how = true;
      this.route.navigate(['/information/12']);

    }
  }

  subBub(x) {
    if (x == 1) {
      this.theState.push('bubbles1');
      this.etoro = false;
      this.smartStock = false;
      this.advanced = false;
      this.diversified = false;

      this.bubbles = false;
      this.bubbles1 = true;
      this.bubbles2 = false;
      this.bubbles3 = false;
      this.bubbles4 = false;
      this.bubbles5 = false;
      this.bubbles6 = false;
      this.how = false;
      this.route.navigate(['/information/6']);

    } else if (x == 2) {
      this.theState.push('bubbles2');
      this.etoro = false;
      this.smartStock = false;
      this.advanced = false;
      this.diversified = false;

      this.bubbles = false;
      this.bubbles1 = false;
      this.bubbles2 = true;
      this.bubbles3 = false;
      this.bubbles4 = false;
      this.bubbles5 = false;
      this.bubbles6 = false;
      this.how = false;
      this.route.navigate(['/information/7']);

    } else if (x == 3) {
      this.theState.push('bubbles3');
      this.etoro = false;
      this.smartStock = false;
      this.advanced = false;
      this.diversified = false;

      this.bubbles = false;
      this.bubbles1 = false;
      this.bubbles2 = false;
      this.bubbles3 = true;
      this.bubbles4 = false;
      this.bubbles5 = false;
      this.bubbles6 = false;
      this.how = false;
      this.route.navigate(['/information/8']);

    } else if (x == 4) {
      this.theState.push('bubbles4');
      this.etoro = false;
      this.smartStock = false;
      this.advanced = false;
      this.diversified = false;

      this.bubbles = false;
      this.bubbles1 = false;
      this.bubbles2 = false;
      this.bubbles3 = false;
      this.bubbles4 = true;
      this.bubbles5 = false;
      this.bubbles6 = false;
      this.how = false;
      this.route.navigate(['/information/9']);

    } else if (x == 5) {
      this.theState.push('bubbles5');
      this.etoro = false;
      this.smartStock = false;
      this.advanced = false;
      this.diversified = false;

      this.bubbles = false;
      this.bubbles1 = false;
      this.bubbles2 = false;
      this.bubbles3 = false;
      this.bubbles4 = false;
      this.bubbles5 = true;
      this.bubbles6 = false;
      this.how = false;
      this.route.navigate(['/information/10']);

    } else if (x == 6) {
      this.theState.push('bubbles6');
      this.etoro = false;
      this.smartStock = false;
      this.advanced = false;
      this.diversified = false;

      this.bubbles = false;
      this.bubbles1 = false;
      this.bubbles2 = false;
      this.bubbles3 = false;
      this.bubbles4 = false;
      this.bubbles5 = false;
      this.bubbles6 = true;
      this.how = false;
      this.route.navigate(['/information/11']);

    }
  }

  return() {
    this.bubbles1 = false;
    this.bubbles2 = false;
    this.bubbles3 = false;
    this.bubbles4 = false;
    this.bubbles5 = false;
    this.bubbles6 = false;
    this.bubbles = true;
    this.route.navigate(['/information/5']);
  }

  backClicked() {
    this._location.back();
  }

  async loginCheck(){
    let username = await localStorage.getItem('username');

    if(username){
        this.route.navigateByUrl('/user');

    }else{
        this.route.navigateByUrl('/login');
        
    }
}

capturePos(){
  // console.log(window.scrollY, "scrollY")
  // console.log(window.screenY, "screenY")
  sessionStorage.setItem("windowPosSubSEO", window.scrollY.toString())
}

@HostListener("window:beforeunload", ["$event"])
refreshDetected(){
  // console.log("refreshed!");
  sessionStorage.setItem("windowPosSubSEO", window.scrollY.toString());
}

removePos(){
  sessionStorage.removeItem("windowPos");
}

}

import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NavbarService } from '../navbarservice.service';

@Component({
  selector: 'app-seo4',
  templateUrl: './seo4.component.html',
  styleUrls: ['./seo4.component.css']
})
export class Seo4Component implements OnInit, AfterViewInit {

  constructor(private route: Router, private meta: Meta, private _location: Location, private nav: NavbarService, private _actRoute: ActivatedRoute) { }
  mobile: Boolean = false;
  ngOnInit(): void {
    this.meta.addTags([
      { name: 'title', content: "Designing a Diversified S&P 500 Trading Algorithm on the IBKR Platform"},
      { name: 'description', content: "Discover a robust S&P 500 trading algorithm on the IBKR platform, featuring various criteria, optimisation methods, and risk management techniques for enhanced performance" }
    ]);

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }

    this.nav.hideSet(true);

    if(sessionStorage.getItem('windowPosSubSEO')){
      sessionStorage.removeItem('windowPosSubSEO');
    }
  }

  ngAfterViewInit(): void {
    this._actRoute.fragment.subscribe(f => {
      const element = document.querySelector("#" + f)
      // console.log(element)
      // console.log(f)
      if (element) element.scrollIntoView();
    })

    let winPosBubbles = sessionStorage.getItem("windowPosBubbles");
    if(winPosBubbles){
      // console.log("found!")
      let numWinPosBubbles = Number(winPosBubbles);
      // window.location.replace('http://localhost:4200/');
      if(numWinPosBubbles > 0){
        // console.log("scrolling!", numWinPosBubbles)
        window.scrollTo(0, numWinPosBubbles);
        // console.log(window.scrollY)
        if(window.scrollY != numWinPosBubbles){
          // console.log("scrolling again!")
          window.scrollTo(0, numWinPosBubbles)
        }
      }
    }
  }

  bubbles = false;
  bubbles1 = false;
  bubbles2 = false;
  bubbles3 = false;
  bubbles4 = false;
  bubbles5 = false;
  bubbles6 = false;

  theState = [];

  
  subBub(x){
    if(x == 1){
      this.theState.push('bubbles1');
      this.bubbles = false;
      this.bubbles1 = true;
      this.bubbles2 = false;
      this.bubbles3 = false;
      this.bubbles4 = false;
      this.bubbles5 = false;
      this.bubbles6 = false;
      this.route.navigate(['information/methodology/runs-on-four-hour-bars']);

    }else if(x == 2){
      this.theState.push('bubbles2');
      this.bubbles = false;
      this.bubbles1 = false;
      this.bubbles2 = true;
      this.bubbles3 = false;
      this.bubbles4 = false;
      this.bubbles5 = false;
      this.bubbles6 = false;
      this.route.navigate(['information/methodology/trades-a-portfolio-of-most-liquid-currencies-indices-and-commodities']);

    }else if(x == 3){
      this.theState.push('bubbles3');
      this.bubbles = false;
      this.bubbles1 = false;
      this.bubbles2 = false;
      this.bubbles3 = true;
      this.bubbles4 = false;
      this.bubbles5 = false;
      this.bubbles6 = false;
      this.route.navigate(['information/methodology/trades-10-separate-algorithms-components-in-parallel']);

    }else if(x == 4){
      this.theState.push('bubbles4');
      this.bubbles = false;
      this.bubbles1 = false;
      this.bubbles2 = false;
      this.bubbles3 = false;
      this.bubbles4 = true;
      this.bubbles5 = false;
      this.bubbles6 = false;
      this.route.navigate(['information/methodology/rolling-window-walk-forward-optimisation-over-7-years-period']);

    }else if(x == 5){
      this.theState.push('bubbles5');
      this.bubbles = false;
      this.bubbles1 = false;
      this.bubbles2 = false;
      this.bubbles3 = false;
      this.bubbles4 = false;
      this.bubbles5 = true;
      this.bubbles6 = false;
      this.route.navigate(['information/methodology/volatility-adjusted-trade-exit-logic']);

    }else if(x == 6){
      this.theState.push('bubbles6');
      this.bubbles = false;
      this.bubbles1 = false;
      this.bubbles2 = false;
      this.bubbles3 = false;
      this.bubbles4 = false;
      this.bubbles5 = false;
      this.bubbles6 = true;
      this.route.navigate(['information/methodology/capital-allocation-and-position-sizing-based-on-optimalf-factors']);

    }

    this.capturePos();
  }

  return(){
    this.bubbles1 = false;
    this.bubbles2 = false;
    this.bubbles3 = false;
    this.bubbles4 = false;
    this.bubbles5 = false;
    this.bubbles6 = false;
    this.bubbles = true;
    this.route.navigate(['information/methodology']);
  }

  backClicked() {
    this._location.back();
  }

  capturePos(){
    // console.log(window.scrollY, "scrollY")
    // console.log(window.screenY, "screenY")
    sessionStorage.setItem("windowPosBubbles", window.scrollY.toString())
  }

  @HostListener("window:beforeunload", ["$event"])
  refreshDetected(){
    // console.log("refreshed!");
    sessionStorage.setItem("windowPosBubbles", window.scrollY.toString());
  }

  removePos(){
    sessionStorage.removeItem("windowPos");
  }

}

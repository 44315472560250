import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from './providers/config/config.service';
import { map, first } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class RegisterserviceService {

  email: string;
  password: string;
  username: string;

  registration(newEmail: string, newPassword: string, newUsername: string): Observable<any> {
    try {
      this.email = newEmail;
      this.password = newPassword;
      this.username = newUsername;

      console.log("service", this.email);
      console.log("service", this.password);
      console.log("service", this.username);


      console.log(this.config.url + "user/register");
      return this.http.post<any>(this.config.url + 'user/register', {email: this.email, password: this.password, username: this.username})
        .pipe(map(user => {
        }));
    } catch (exception) {
      console.log(exception);
    }
  }

  constructor(private http: HttpClient, private config: ConfigService) { }
}

import { Component, OnInit, HostListener, OnDestroy, AfterViewInit } from '@angular/core';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { Router } from '@angular/router';

import { NavbarService } from '../navbarservice.service';


@Component({
  selector: 'app-academy',
  templateUrl: './academy.component.html',
  styleUrls: ['./academy.component.css']
})
export class AcademyComponent implements OnInit {

  closeResult: any;
  mobile: Boolean = false;

  constructor(private modalService: NgbModal, private router: Router, public nav: NavbarService) {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => {
    //   return false;
    // };
  }

  ngOnInit(): void {

    this.checkSelection();

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.mobile = true;
    } else {
      this.mobile = false;;
    }

    this.nav.hideSet(true);
  }

  ibkr;
  etoro;
  fxcm;
  platform;

  checkSelection(){
    let x = localStorage.getItem('selection');

    if(x == 'ibkr'){
      this.ibkr = true;
      this.fxcm = false;
      this.etoro = false;
      this.platform = 'IBKR'
    }else if(x == 'fxcm'){
      this.fxcm = true;
      this.ibkr = false;
      this.etoro = false;
      this.platform = 'FXCM';
    }else if(x == 'etoro'){
      this.etoro = true;
      this.fxcm = false;
      this.ibkr = false;
      this.platform = 'eToro';
    }
  }

  async theTimer() {
    await new Promise(f => setTimeout(f, 2000));
    window.history.replaceState({ page: window.location.href }, null, "/academy");

  }



  open(content) {
    this.modalService.open(content, { windowClass: "windowSize" });
  }

}

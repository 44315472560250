import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  constructor() { }

  base64 = {
    "nsdqB64": "",
    "chartB64": ""
  }
  setB64(nsdq, chart){
    this.base64.nsdqB64 = nsdq;
    this.base64.chartB64 = chart;
  };

  getB64(){
    return this.base64;
  };
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'; // <-- do not forget to import
import { NavbarService } from '../navbarservice.service';





// import * as GoogleAuth from 'google-auth-library';
// import * as google from 'googleapis';




@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.css']
})
export class TestingComponent implements OnInit {


  mobile: Boolean = false;

  constructor(private _actRoute: ActivatedRoute,
    public nav: NavbarService) {

  }

  ngOnInit(): void {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }


    this.nav.hideSet(false);

    this._actRoute.fragment.subscribe(f => {
      const element = document.querySelector("#" + f)
      if (element) element.scrollIntoView()
    })
  }

}


import { Component, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-fxcmvideo-content',
  template: `
  <div class="modal-body" style="text-align: center;"> 
      <div class="row">
          <div class="col">
              <div class="video-container">
              <iframe src="https://drive.google.com/file/d/15RBeyJNrxYB59kUKHRW8CEoo6puiODll/preview" width="1000"
        height="300" allow="autoplay"></iframe>
              </div>
          </div>
      </div>
  </div>
  <br/>
  <div class="modal-footer">
      <div class="left-side" style="margin: auto">
          <button type="button" class="btn" (click)="activeModal.close('Close click')">Close</button>
      </div>
      <div class="divider"></div>
  </div>
  `,
  styleUrls: ['./fxcmvideo.component.css']
})

export class FXCMVideoContent {
  @Input() name;

  constructor(public activeModal: NgbActiveModal) { }
}

@Component({
  selector: 'app-fxcmvideo-component',
  templateUrl: './fxcmvideo.component.html',
  styleUrls: ['./fxcmvideo.component.css']

})
export class FXCMVideoComponent  {
  constructor(private modalService: NgbModal) { }
  open() {
      const modalRef = this.modalService.open(FXCMVideoContent, { windowClass: 'largeModal' });
      modalRef.componentInstance.name = 'World';
  }

}

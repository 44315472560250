import { Component, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-index-video-content',
    template: `
    <div class="modal-body" style="text-align: center;"> 
        <div class="row">
            <div class="col">
                <div class="video-container">
                <iframe src="https://drive.google.com/file/d/15ITmR2CEnCB45cvQviaI18EWAx56sMoU/preview" width="1000"
					height="300" allow="autoplay"></iframe>
                </div>
            </div>
        </div>
    </div>
    <br/>
    <div class="modal-footer">
        <div class="left-side" style="margin: auto">
            <button type="button" class="btn" (click)="activeModal.close('Close click')">Close</button>
        </div>
        <div class="divider"></div>
    </div>
    `,
    styleUrls: ['./index-video.component.css']
})
export class IndexVideoContent {
    @Input() name;

    constructor(public activeModal: NgbActiveModal) { }
}

@Component({
    selector: 'app-index-video-component',
    templateUrl: './index-video.component.html',
    styleUrls: ['./index-video.component.css']

})
export class IndexVideoComponent {
    constructor(private modalService: NgbModal) { }
    open() {
        const modalRef = this.modalService.open(IndexVideoContent, { windowClass: 'largeModal' });
        modalRef.componentInstance.name = 'World';
    }
}

import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { SignupComponent } from './signup/signup.component';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './login/login.component';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { MultimediaComponent } from './multimedia/multimedia.component';
import { ChartsComponent } from './charts/charts.component';
import { UserStatsComponent } from './user-stats/user-stats.component';
import { CosmosComponent } from './cosmos/cosmos.component';
import { TestingComponent } from './testing/testing.component';
import { RegisterComponent } from './register/register.component';
import { AdminComponent } from './admin/admin.component';
import { AcademyComponent } from './academy/academy.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { SeoComponent } from './seo/seo.component';
import { Seo1Component } from './seo1/seo1.component';
import { Seo2Component } from './seo2/seo2.component';
import { Seo3Component } from './seo3/seo3.component';
import { Seo4Component } from './seo4/seo4.component';
import { Seo5Component } from './seo5/seo5.component';
import { Seo6Component } from './seo6/seo6.component';
import { Seo7Component } from './seo7/seo7.component';
import { Seo8Component } from './seo8/seo8.component';
import { Seo9Component } from './seo9/seo9.component';
import { Seo10Component } from './seo10/seo10.component';
import { Seo11Component } from './seo11/seo11.component';
import { FxcmHomeComponent } from './fxcm-home/fxcm-home.component';
import { ProfileLandingComponent } from './profile-landing/profile-landing.component';
import { LiveLandingComponent } from './live-landing/live-landing.component';

const routes: Routes = [


  // { path: '', redirectTo: '', pathMatch: 'full' },
  //{ path: 'questionnaire',  component: QuestionnaireComponent },
  // { path: 'multimedia',     component: MultimediaComponent },
  { path: 'login', component: LoginComponent },
  { path: 'etoro', component: ChartsComponent },
  { path: 'user', component: UserStatsComponent },
  { path: 'cosmos', component: CosmosComponent },
  { path: '', component: TestingComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'academy', component: AcademyComponent },
  { path: 'newsletter', component: NewsletterComponent },
  { path: 'information/about', component: SeoComponent },
  { path: 'information/smart-stock-curation', component: Seo1Component },
  { path: 'information/advanced-analytics', component: Seo2Component },
  { path: 'information/diversified-approach', component: Seo3Component },
  { path: 'information/methodology', component: Seo4Component },
  { path: 'information/methodology/runs-on-four-hour-bars', component: Seo5Component },
  { path: 'information/methodology/trades-a-portfolio-of-most-liquid-currencies-indices-and-commodities', component: Seo6Component },
  { path: 'information/methodology/trades-10-separate-algorithms-components-in-parallel', component: Seo7Component },
  { path: 'information/methodology/rolling-window-walk-forward-optimisation-over-7-years-period', component: Seo8Component },
  { path: 'information/methodology/volatility-adjusted-trade-exit-logic', component: Seo9Component },
  { path: 'information/methodology/capital-allocation-and-position-sizing-based-on-optimalf-factors', component: Seo10Component },
  { path: 'information/how-it-works', component: Seo11Component },
  { path: 'home/fxcm', component: FxcmHomeComponent},
  { path: 'platform/profiles', component: ProfileLandingComponent},
  { path: 'platform/live', component: LiveLandingComponent}


  // { path: 'admin',          component: AdminComponent}
  // { path: '', redirectTo: 'home', pathMatch: 'full' }

];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      // useHash: true
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { MultimediaserviceService } from '../multimediaservice.service';
import * as myGlobals from 'Globals';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { addEmitHelper } from 'typescript';

@Component({
  selector: 'app-multimedia',
  templateUrl: './multimedia.component.html',
  styleUrls: ['./multimedia.component.css']
})


export class MultimediaComponent implements OnInit {

  newsletterTitle: string;
  newsletterBody: string;

  display = true;

  b64String : any;

  shortLink: string = "";
  loading: boolean = false; // Flag variable
  file: File = null;

  constructor(private multimediaService: MultimediaserviceService) { }

  ngOnInit(): void {
    /*this.multimediaService.makeAdmin().subscribe(e => {

    })*/

    let userRole = this.multimediaService.getRole();
    if(userRole != "admin"){
      this.display = false;
    }
  }

  async onChange(event) {
    this.file = event.target.files[0];
    console.log(this.file);

    var file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var pattern = /pdf-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload =  await this._setUploadPDF.bind(this);
    reader.readAsDataURL(file);
  }

    /*var reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = (function (theFile) {
     
      return function (e) {
        this.file = theFile;
        var binaryData = e.target.result;
        //Converting Binary Data to base 64
        var base64String = window.btoa(binaryData);
        this.b64String = base64String;
        myGlobals.globalBase = base64String;
      };
    })(this.file);
    reader.readAsBinaryString(this.file);*/
  pdfSrcDoc;
    async _setUploadPDF(e){
      let reader = e.target;

    const result = reader.result;
    // this.openSnackBar(result.length,"OK")
    if (!result) {
      console.log("errr");
      this.pdfSrcDoc = '';
    }
    if (result.length > 400000) {
      console.log("File size should be less than 300KB", "OK")
      this.pdfSrcDoc = '';
    }
    else {
      this.pdfSrcDoc = reader.result;
      await this.onUpload();
    }
  }

  
  b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }



async onUpload() {
      /*let blobObj = this.b64toBlob(myGlobals.globalBase, 'application/pdf');
      console.log("blob",blobObj);

      let b64 = myGlobals.globalBase;
      this.loading = !this.loading;*/
      console.log(this.pdfSrcDoc);
      await this.multimediaService.upload(this.newsletterTitle, this.newsletterBody, this.pdfSrcDoc).subscribe(
        (event: any) => {
          if (typeof (event) === 'object') {

            // Short link via api response
            this.shortLink = event.link;

            this.loading = false; // Flag variable 
          }
        }
      );
    }

  }
